.NavbarItems {
    height: 80px;
    
    list-style: none;
    
    


}
.NavbarItems2 {
    background-color: blue;
}
li a{
    text-align: center;
    text-decoration: none;
    padding: 16px;
}
li{
    float: right;
}


ul{
    overflow: hidden;
    list-style-type: none;
}
.sticky-nav {
    position: sticky;
    top: 0;
    left: 0;
}
.nav-links{
    color: white;
}
.viddilogo{
    text-align: right;
    
}