.search{
    background-color: rgb(255, 255, 255);
    width: 300px;
    border: 5px;
    padding: 20px;
    margin: 20px;
    float: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

}
.buttonstyle {
    background-color: #3764b6; /* Green */
    border: none;
    color: white;
    padding: 20px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
}
.search-results {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px blue;
    background-color: #434747;
    color: #ffffff;
    text-align: left;
}

.search-results th,
.search-results td {
    padding: 12px 15px;
}

.search-results tbody tr {
    border-bottom: 1px solid #dddddd;
}

.search-results tbody tr:nth-of-type(even) {
    background-color: #b0b1b1;
}

.search-results tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.search-results tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.searchtext {
    color: white;
}