* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
body {
  /*background-image: url(./mountains.jpg);
  background-size: cover;
  background-repeat:no-repeat;*/
}