.footer {
    padding: 20px;
  }
  
  .container {
    text-align: center;
  }
  
  .viddifooter {
    color: red;
  }
  