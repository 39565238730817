.downloadIT {
  background-color: #3764b6; /* Green */
  border: none;
  color: white;
  padding: 20px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}
button:hover {
  background-color: rgb(5, 177, 5);
    
}
h1 {
    margin-left: 20px;
}

