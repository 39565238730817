.TextBackground{
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    color:black;
    background: rgba(255, 255, 255, 0.8);
}
.BoxBackground{
    width: 80%;
    height: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
    color:black;
    background: rgba(151, 151, 151, 0.8);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow:hidden;
}
.BoxOne{
    background: rgba(148, 190, 252, 0.4);
    border-radius: 4px;
    font-family: Arial;
    border: 2px solid rgb(2, 43, 77);
    text-align: left;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.BoxTwo{
    background: rgba(148, 190, 252, 0.4);
    border-radius: 4px;
    font-family: Arial;
    border: 2px solid rgb(2, 43, 77);
    text-align: left;
    min-height: 90px;
    margin-bottom: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.underScore{
    text-decoration: underline;
}
.listOne{
    display: grid;
    grid-template-columns: 1fr;
    list-style-type: none;
}
*{
    -ms-overflow-style: none;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  *,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
  .start-bg {
    background: url("../../public/IMG_1303.jpg")  top left fixed;
    background-size: cover;
    height: 74vh;
    object-fit: cover;
  }